<template>
  <v-container class="fill-height">
    <v-row class="fill-height">
      <v-col md="2" class="hidden-sm-and-down" />
      <v-col md="8">
        <v-stepper v-model="stepNr">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="stepNr > 1" step="1">
              In je mandje
            </v-stepper-step>
            <v-divider />
            <v-stepper-step :complete="stepNr > 2" step="2">
              Bestellen
            </v-stepper-step>
            <v-divider />
            <v-stepper-step step="3"> Gereed </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <!-- STEP 1 -->
            <v-stepper-content step="1">
              <v-row dense
                ><v-col cols="12" align="center">
                  <v-sheet
                    outlined
                    min-height="350"
                    max-height="350"
                    style="overflow-y: auto"
                    class="mx-auto"
                  >
                    <v-data-table
                      :items="cartItems"
                      :headers="tableHeaders"
                      disable-pagination
                      disable-sort
                      hide-default-footer
                      no-data-text
                      fixed-header
                    >
                      <template v-slot:item.actions="{ item }">
                        <v-icon small @click="deleteCartItem(item)"
                          >mdi-delete</v-icon
                        >
                      </template>
                    </v-data-table>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="6" class="pt-3">
                  <v-btn plain :to="{ name: 'ECatalog' }">
                    <v-icon class="ml-n4">mdi-chevron-left</v-icon>Verder
                    winkelen
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" class="pt-3" align="right">
                  <v-btn
                    v-show="cartItems"
                    text
                    color="primary"
                    @click="stepNr = 2"
                  >
                    Verder bestellen
                    <v-icon class="mr-n4">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- STEP 2 -->
            <v-stepper-content step="2">
              <v-row dense
                ><v-col cols="12" align="center">
                  <v-sheet
                    outlined
                    min-height="350"
                    max-height="350"
                    style="overflow-y: auto"
                    class="mx-auto"
                  >
                    <v-textarea
                      v-model="remark"
                      auto-grow
                      autofocus
                      solo
                      flat
                      placeholder="Je kan hier nog een opmerking meegeven"
                    ></v-textarea>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="6" class="pt-3">
                  <v-btn plain @click="stepNr = 1">
                    <v-icon class="ml-n4">mdi-chevron-left</v-icon>Vorige stap
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" align="right" class="pt-3">
                  <v-btn
                    outlined
                    :loading="loading"
                    color="primary"
                    @click.stop="convertCart"
                  >
                    Bestelling afronden
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <!-- STEP 3 -->
            <v-stepper-content step="3">
              <v-row dense
                ><v-col cols="12">
                  <v-sheet
                    min-height="350"
                    max-height="350"
                    style="overflow-y: auto"
                    class="mx-auto"
                  >
                    <v-banner
                      :icon="errorMsg ? 'mdi-close' : 'mdi-check'"
                      :icon-color="errorMsg ? 'red' : 'green'"
                    >
                      <span v-if="errorMsg"> {{ this.errorMsg }}</span>
                      <span v-else>
                        {{ this.result }}
                      </span>
                    </v-banner>
                  </v-sheet>
                </v-col>
                <v-col cols="12" sm="6" class="pt-3">
                  <v-btn plain :to="{ name: 'ECatalog' }">
                    Verder werken
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" align="right" class="pt-3">
                  <v-btn
                    :loading="loading"
                    color="primary"
                    @click.stop="logout"
                  >
                    Afmelden
                    <v-chip
                      v-if="timeToLogout"
                      class="ml-2"
                      outlined
                      small
                      color="white"
                      text-color="white"
                      >{{ timeToLogout }}</v-chip
                    >
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col md="2" class="hidden-sm-and-down" />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ECart",
  data() {
    return {
      errorMsg: null,
      remark: "",
      result: "",
      stepNr: 1,
      loading: false,
      tableHeaders: [
        {
          text: "Code",
          value: "productCode",
          align: "left"
        },
        {
          text: "Product",
          value: "productName",
          width: "70%"
        },
        {
          text: "Aantal",
          value: "quantity",
          align: "right"
        },
        {
          text: "",
          value: "actions",
          align: "center",
          width: "10%"
        }
      ],
      timeToLogout: null
    };
  },
  computed: {
    ...mapGetters({
      cartItems: "shoppingCart/cartItems"
    })
  },
  created() {
    this.$store.dispatch("shoppingCart/fetchShoppingCart");
  },
  methods: {
    convertCart() {
      let that = this;
      this.loading = true;
      this.$store
        .dispatch("shoppingCart/convertShoppingCart", this.remark)
        .then(() => {
          this.result = "Je bestelling is goed ontvangen";
        })
        .catch(error => {
          this.errorMsg = error;
        })
        .finally(() => {
          window.setTimeout(function() {
            that.stepNr = 3;
            that.loading = false;
            that.timeToLogout = 30;
            setInterval(that.countdown, 1000);
          }, 1250);
        });
    },
    deleteCartItem(pCartItem) {
      this.$store.dispatch("shoppingCart/deleteCartItem", pCartItem);
    },
    logout() {
      this.$store.dispatch("session/logout").finally(() => {
        this.drawer = null;
      });
    },
    countdown() {
      this.timeToLogout -= 1;
      if (this.timeToLogout === 0 && this.$route.name === "ECart") {
        this.timeToLogout = null;
        this.logout();
      }
    }
  }
};
</script>
